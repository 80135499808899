/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {


        $(".mobile-nav-toggle").on("click", function(e){

            $(".mobile-navigation-container").toggleClass("opened");
            $(".overlay-nav").fadeToggle(100);
            e.preventDefault();
        });



        // JavaScript to be fired on all pages, after page specific JS is fired
        $(window).scroll(function() {
          if ($(window).scrollTop() > 70) {
            $(".header-bar").addClass("scrolled");

          } else {
        
            $(".header-bar").removeClass("scrolled");
          }
        });


        $(".fade-in-section-home").addClass("is-visible");

       $(window).scroll(function () {
          $('.fade-in-section, .fade-in-section-left, .fade-in-section-right').each(function (i) {
              var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.05);
              var bottom_of_window = $(window).scrollTop() + $(window).height();
              if (bottom_of_window > bottom_of_object) {
                  $(this).addClass('is-visible');
              }
          });
        });


       $("#projectSlider").slick({
            
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            
            autoplay: false,
            arrows: true,
            prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-chevron-left'></span></a>",
            nextArrow: "<a href='#' class='slick-next'><span class='fa fa-chevron-right'></span></a>",
            
        });

        $("#testimonialSlider").slick({
            
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            
            autoplay: false,
            arrows: true,
            adaptiveHeight: true,
            fade: true,
            cssEase: 'linear',
            prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-chevron-left'></span></a>",
            nextArrow: "<a href='#' class='slick-next'><span class='fa fa-chevron-right'></span></a>",
            
        });
      }
    },
    // Home page
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $("#emailAddress").on('keyup blur input keydown change', function(){

             var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

             var email = $(this).val();

             if(emailPattern.test(email)){
                $("#emailAddress").addClass("valid");
                $("#emailAddress").removeClass("error");
                $("#emailAddress").parent().find(".error-message").remove();

             }else{
                $("#emailAddress").removeClass("valid");
                $("#emailAddress").addClass("error");

             }


        });

        $("#phoneNumber").on('keyup blur input keydown change', function(){

             var phonePattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

             var phone = $(this).val();

             if(phonePattern.test(phone)){
                $("#phoneNumber").addClass("valid");
                $("#phoneNumber").removeClass("error");
                $("#phoneNumber").parent().find(".error-message").remove();

             }else{
                $("#phoneNumber").removeClass("valid");
                $("#phoneNumber").addClass("error");

             }


        });


        $("#fullName").on('keyup blur input', function(){

         
          var name = $(this).val();

            if (name.length >= 2) {
              
              $(this).removeClass("error");
              $(this).addClass("valid");
              $(this).parent().find(".error-message").remove();
              
            }else{
               $(this).removeClass("valid");
              $(this).addClass("error");
            }
        });


        $("#contactMessage").on('keyup blur input', function(){

         
          var name = $(this).val();

            if (name.length >= 2) {
              
              $(this).removeClass("error");
              $(this).addClass("valid");
              $(this).parent().find(".error-message").remove();
              
            }else{
               $(this).removeClass("valid");
              $(this).addClass("error");
            }
        });




        $(".btn-submit").on("click", function(e){

            e.preventDefault();

            var validCount = 0;

           if($("#fullName").hasClass("valid")){
              validCount++;
           }else{
              $("#fullName").parent().find(".error-message").remove();
              $("#fullName").addClass("error");
              $("#fullName").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Your full name is required.</span>");
           }


           if($("#emailAddress").hasClass("valid")){
              validCount++;
           }else{
              $("#emailAddress").parent().find(".error-message").remove();
              $("#emailAddress").addClass("error");
              $("#emailAddress").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Not a valid email address.</span>");
           }


           if($("#phoneNumber").hasClass("valid")){
              validCount++;
           }else{
              $("#phoneNumber").parent().find(".error-message").remove();
              $("#phoneNumber").addClass("error");
              $("#phoneNumber").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Not a valid phone number.</span>");
           }

           if($("#contactMessage").hasClass("valid")){
              validCount++;
           }else{
              $("#contactMessage").parent().find(".error-message").remove();
              $("#contactMessage").addClass("error");
              $("#contactMessage").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Not an accepted message length. Try again and be more descriptive.</span>");
           }

          


           if(validCount > 3){

                $(".contact-form")[0].submit();

           } else {
                return false;
           }

        });
        

      }
    },
    // About us page, note the change from about-us to about_us.
    'project_estimate': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function(){
        $("#emailAddress").on('keyup blur input keydown change', function(){

             var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

             var email = $(this).val();

             if(emailPattern.test(email)){
                $("#emailAddress").addClass("valid");
                $("#emailAddress").removeClass("error");
                $("#emailAddress").parent().find(".error-message").remove();

             }else{
                $("#emailAddress").removeClass("valid");
                $("#emailAddress").addClass("error");

             }


        });

        $("#phoneNumber").on('keyup blur input keydown change', function(){

             var phonePattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

             var phone = $(this).val();

             if(phonePattern.test(phone)){
                $("#phoneNumber").addClass("valid");
                $("#phoneNumber").removeClass("error");
                $("#phoneNumber").parent().find(".error-message").remove();

             }else{
                $("#phoneNumber").removeClass("valid");
                $("#phoneNumber").addClass("error");

             }


        });


        $("#fullName").on('keyup blur input', function(){

         
          var name = $(this).val();

            if (name.length >= 2) {
              
              $(this).removeClass("error");
              $(this).addClass("valid");
              $(this).parent().find(".error-message").remove();
              
            }else{
               $(this).removeClass("valid");
              $(this).addClass("error");
            }
        });


        $("#contactMessage").on('keyup blur input', function(){

         
          var name = $(this).val();

            if (name.length >= 2) {
              
              $(this).removeClass("error");
              $(this).addClass("valid");
              $(this).parent().find(".error-message").remove();
              
            }else{
               $(this).removeClass("valid");
              $(this).addClass("error");
            }
        });




        $(".btn-submit").on("click", function(e){

            e.preventDefault();

            var validCount = 0;

           if($("#fullName").hasClass("valid")){
              validCount++;
           }else{
              $("#fullName").parent().find(".error-message").remove();
              $("#fullName").addClass("error");
              $("#fullName").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Your full name is required.</span>");
           }


           if($("#emailAddress").hasClass("valid")){
              validCount++;
           }else{
              $("#emailAddress").parent().find(".error-message").remove();
              $("#emailAddress").addClass("error");
              $("#emailAddress").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Not a valid email address.</span>");
           }


           if($("#phoneNumber").hasClass("valid")){
              validCount++;
           }else{
              $("#phoneNumber").parent().find(".error-message").remove();
              $("#phoneNumber").addClass("error");
              $("#phoneNumber").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Not a valid phone number.</span>");
           }

           if($("#contactMessage").hasClass("valid")){
              validCount++;
           }else{
              $("#contactMessage").parent().find(".error-message").remove();
              $("#contactMessage").addClass("error");
              $("#contactMessage").parent().append("<span class='text-danger error-message'><span class='fa fa-exclamation-triangle'></span> Not an accepted message length. Try again and be more descriptive.</span>");
           }

          


           if(validCount > 3){

                $(".contact-form")[0].submit();

           } else {
                return false;
           }

        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
